import React from "react"
import * as Icon from "react-feather"

const navigationConfig = [
    {
        id: "General",
        title: "General info",
        type: "item",
        icon: <Icon.User size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/"
    },
    {
        id: "Changepassword",
        title: "Change password",
        type: "item",
        icon: <Icon.Settings size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/ChangePassword"
    },
    {
        id: "Infos",
        title: "info",
        type: "item",
        icon: <Icon.Info size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/info"
    },
    {
        id: "Education",
        title: "Education",
        type: "item",
        icon: <Icon.BookOpen size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/Education"
    },
    {
        id: "Social Links",
        title: "Social Links",
        type: "item",
        icon: <Icon.Globe size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/sociallinks"
    },
    // {
    //   id: "page8",
    //   title: "Orders",
    //   type: "item",
    //   icon: <Icon.List size={20} />,
    //   permissions: ["admin", "editor"],
    //   navLink: "/AllOrdersPage"
    // },{
    //   id: "page5",
    //   title: "Transactions",
    //   type: "item",
    //   icon: <Icon.Repeat size={20} />,
    //   permissions: ["admin", "editor"],
    //   navLink: "/transactionspage"
    // }
    // {
    //   id: "page2",
    //   title: "progress",
    //   type: "item",
    //   icon: <Icon.BookOpen size={20} />,
    //   permissions: ["admin", "editor"],
    //   navLink: "/page2"
    // }
    // ,
    // {
    //   id: "page3",
    //   title: "Active course",
    //   type: "item",
    //   icon: <Icon.BookOpen size={20} />,
    //   permissions: ["admin", "editor"],
    //   navLink: "/page3"
    // },

]

export default navigationConfig
